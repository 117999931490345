// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-connect-ar-vr-ar-vr-development-js": () => import("./../../../src/pages/connect/ar-vr/ar-vr-development.js" /* webpackChunkName: "component---src-pages-connect-ar-vr-ar-vr-development-js" */),
  "component---src-pages-connect-blockchain-blockchain-consulting-js": () => import("./../../../src/pages/connect/blockchain/blockchain-consulting.js" /* webpackChunkName: "component---src-pages-connect-blockchain-blockchain-consulting-js" */),
  "component---src-pages-connect-blockchain-blockchain-development-js": () => import("./../../../src/pages/connect/blockchain/blockchain-development.js" /* webpackChunkName: "component---src-pages-connect-blockchain-blockchain-development-js" */),
  "component---src-pages-connect-cloud-aws-development-js": () => import("./../../../src/pages/connect/cloud/aws-development.js" /* webpackChunkName: "component---src-pages-connect-cloud-aws-development-js" */),
  "component---src-pages-connect-cloud-cloud-consulting-js": () => import("./../../../src/pages/connect/cloud/cloud-consulting.js" /* webpackChunkName: "component---src-pages-connect-cloud-cloud-consulting-js" */),
  "component---src-pages-connect-cloud-cloud-devops-js": () => import("./../../../src/pages/connect/cloud/cloud-devops.js" /* webpackChunkName: "component---src-pages-connect-cloud-cloud-devops-js" */),
  "component---src-pages-connect-cloud-cloud-migration-js": () => import("./../../../src/pages/connect/cloud/cloud-migration.js" /* webpackChunkName: "component---src-pages-connect-cloud-cloud-migration-js" */),
  "component---src-pages-connect-cloud-google-cloud-development-js": () => import("./../../../src/pages/connect/cloud/google-cloud-development.js" /* webpackChunkName: "component---src-pages-connect-cloud-google-cloud-development-js" */),
  "component---src-pages-connect-games-game-design-and-development-js": () => import("./../../../src/pages/connect/games/game-design-and-development.js" /* webpackChunkName: "component---src-pages-connect-games-game-design-and-development-js" */),
  "component---src-pages-connect-games-unity-development-js": () => import("./../../../src/pages/connect/games/unity-development.js" /* webpackChunkName: "component---src-pages-connect-games-unity-development-js" */),
  "component---src-pages-connect-iot-iot-development-jsx": () => import("./../../../src/pages/connect/iot/iot-development.jsx" /* webpackChunkName: "component---src-pages-connect-iot-iot-development-jsx" */),
  "component---src-pages-connect-metaverse-metaverse-development-js": () => import("./../../../src/pages/connect/metaverse/metaverse-development.js" /* webpackChunkName: "component---src-pages-connect-metaverse-metaverse-development-js" */),
  "component---src-pages-connect-mobile-flutter-development-js": () => import("./../../../src/pages/connect/mobile/flutter-development.js" /* webpackChunkName: "component---src-pages-connect-mobile-flutter-development-js" */),
  "component---src-pages-connect-mobile-react-native-development-js": () => import("./../../../src/pages/connect/mobile/react-native-development.js" /* webpackChunkName: "component---src-pages-connect-mobile-react-native-development-js" */),
  "component---src-pages-connect-mvp-mvp-development-js": () => import("./../../../src/pages/connect/mvp/mvp-development.js" /* webpackChunkName: "component---src-pages-connect-mvp-mvp-development-js" */),
  "component---src-pages-connect-qa-qa-testing-automation-jsx": () => import("./../../../src/pages/connect/qa/qa-testing-automation.jsx" /* webpackChunkName: "component---src-pages-connect-qa-qa-testing-automation-jsx" */),
  "component---src-pages-connect-qa-qa-testing-jsx": () => import("./../../../src/pages/connect/qa/qa-testing.jsx" /* webpackChunkName: "component---src-pages-connect-qa-qa-testing-jsx" */),
  "component---src-pages-connect-web-python-development-jsx": () => import("./../../../src/pages/connect/web/python-development.jsx" /* webpackChunkName: "component---src-pages-connect-web-python-development-jsx" */),
  "component---src-pages-connect-web-web-backend-development-jsx": () => import("./../../../src/pages/connect/web/web-backend-development.jsx" /* webpackChunkName: "component---src-pages-connect-web-web-backend-development-jsx" */),
  "component---src-pages-connect-web-web-development-jsx": () => import("./../../../src/pages/connect/web/web-development.jsx" /* webpackChunkName: "component---src-pages-connect-web-web-development-jsx" */),
  "component---src-pages-connect-web-web-frontend-development-jsx": () => import("./../../../src/pages/connect/web/web-frontend-development.jsx" /* webpackChunkName: "component---src-pages-connect-web-web-frontend-development-jsx" */),
  "component---src-pages-contact-follow-up-js": () => import("./../../../src/pages/contact/follow-up.js" /* webpackChunkName: "component---src-pages-contact-follow-up-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-fraud-disclaimer-js": () => import("./../../../src/pages/fraud-disclaimer.js" /* webpackChunkName: "component---src-pages-fraud-disclaimer-js" */),
  "component---src-pages-how-js": () => import("./../../../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-get-started-js": () => import("./../../../src/pages/lets-get-started.js" /* webpackChunkName: "component---src-pages-lets-get-started-js" */),
  "component---src-pages-location-canada-office-js": () => import("./../../../src/pages/location/canada-office.js" /* webpackChunkName: "component---src-pages-location-canada-office-js" */),
  "component---src-pages-location-islamabad-office-js": () => import("./../../../src/pages/location/islamabad-office.js" /* webpackChunkName: "component---src-pages-location-islamabad-office-js" */),
  "component---src-pages-location-lahore-office-js": () => import("./../../../src/pages/location/lahore-office.js" /* webpackChunkName: "component---src-pages-location-lahore-office-js" */),
  "component---src-pages-location-usa-office-california-js": () => import("./../../../src/pages/location/usa-office-california.js" /* webpackChunkName: "component---src-pages-location-usa-office-california-js" */),
  "component---src-pages-location-usa-office-raleigh-js": () => import("./../../../src/pages/location/usa-office-raleigh.js" /* webpackChunkName: "component---src-pages-location-usa-office-raleigh-js" */),
  "component---src-pages-modified-scrum-model-js": () => import("./../../../src/pages/modified-scrum-model.js" /* webpackChunkName: "component---src-pages-modified-scrum-model-js" */),
  "component---src-pages-portfolio-alexgo-js": () => import("./../../../src/pages/portfolio/alexgo.js" /* webpackChunkName: "component---src-pages-portfolio-alexgo-js" */),
  "component---src-pages-portfolio-audio-cardio-js": () => import("./../../../src/pages/portfolio/audio-cardio.js" /* webpackChunkName: "component---src-pages-portfolio-audio-cardio-js" */),
  "component---src-pages-portfolio-bbqguru-js": () => import("./../../../src/pages/portfolio/bbqguru.js" /* webpackChunkName: "component---src-pages-portfolio-bbqguru-js" */),
  "component---src-pages-portfolio-beatbugs-js": () => import("./../../../src/pages/portfolio/beatbugs.js" /* webpackChunkName: "component---src-pages-portfolio-beatbugs-js" */),
  "component---src-pages-portfolio-brilliantly-warm-js": () => import("./../../../src/pages/portfolio/brilliantly-warm.js" /* webpackChunkName: "component---src-pages-portfolio-brilliantly-warm-js" */),
  "component---src-pages-portfolio-cryptocurrency-exchange-platform-js": () => import("./../../../src/pages/portfolio/cryptocurrency-exchange-platform.js" /* webpackChunkName: "component---src-pages-portfolio-cryptocurrency-exchange-platform-js" */),
  "component---src-pages-portfolio-decorist-room-builder-js": () => import("./../../../src/pages/portfolio/decorist-room-builder.js" /* webpackChunkName: "component---src-pages-portfolio-decorist-room-builder-js" */),
  "component---src-pages-portfolio-finance-app-js": () => import("./../../../src/pages/portfolio/finance-app.js" /* webpackChunkName: "component---src-pages-portfolio-finance-app-js" */),
  "component---src-pages-portfolio-food-allergen-sensor-js": () => import("./../../../src/pages/portfolio/food-allergen-sensor.js" /* webpackChunkName: "component---src-pages-portfolio-food-allergen-sensor-js" */),
  "component---src-pages-portfolio-fortune-500-ar-transformation-js": () => import("./../../../src/pages/portfolio/fortune500-AR-transformation.js" /* webpackChunkName: "component---src-pages-portfolio-fortune-500-ar-transformation-js" */),
  "component---src-pages-portfolio-gamerize-js": () => import("./../../../src/pages/portfolio/gamerize.js" /* webpackChunkName: "component---src-pages-portfolio-gamerize-js" */),
  "component---src-pages-portfolio-genopets-js": () => import("./../../../src/pages/portfolio/genopets.js" /* webpackChunkName: "component---src-pages-portfolio-genopets-js" */),
  "component---src-pages-portfolio-jobbox-js": () => import("./../../../src/pages/portfolio/jobbox.js" /* webpackChunkName: "component---src-pages-portfolio-jobbox-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-lifeprint-js": () => import("./../../../src/pages/portfolio/lifeprint.js" /* webpackChunkName: "component---src-pages-portfolio-lifeprint-js" */),
  "component---src-pages-portfolio-omni-fitness-js": () => import("./../../../src/pages/portfolio/omni-fitness.js" /* webpackChunkName: "component---src-pages-portfolio-omni-fitness-js" */),
  "component---src-pages-portfolio-raven-js": () => import("./../../../src/pages/portfolio/raven.js" /* webpackChunkName: "component---src-pages-portfolio-raven-js" */),
  "component---src-pages-portfolio-sesame-js": () => import("./../../../src/pages/portfolio/sesame.js" /* webpackChunkName: "component---src-pages-portfolio-sesame-js" */),
  "component---src-pages-portfolio-swvl-js": () => import("./../../../src/pages/portfolio/swvl.js" /* webpackChunkName: "component---src-pages-portfolio-swvl-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-project-delivery-process-js": () => import("./../../../src/pages/project-delivery-process.js" /* webpackChunkName: "component---src-pages-project-delivery-process-js" */),
  "component---src-pages-services-2-d-3-d-art-js": () => import("./../../../src/pages/services/2d-3d-art.js" /* webpackChunkName: "component---src-pages-services-2-d-3-d-art-js" */),
  "component---src-pages-services-ai-ml-js": () => import("./../../../src/pages/services/ai-ml.js" /* webpackChunkName: "component---src-pages-services-ai-ml-js" */),
  "component---src-pages-services-app-development-js": () => import("./../../../src/pages/services/app-development.js" /* webpackChunkName: "component---src-pages-services-app-development-js" */),
  "component---src-pages-services-game-development-js": () => import("./../../../src/pages/services/game-development.js" /* webpackChunkName: "component---src-pages-services-game-development-js" */),
  "component---src-pages-services-iot-js": () => import("./../../../src/pages/services/iot.js" /* webpackChunkName: "component---src-pages-services-iot-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-ui-ux-js": () => import("./../../../src/pages/services/ui-ux.js" /* webpackChunkName: "component---src-pages-services-ui-ux-js" */),
  "component---src-pages-services-web-development-hire-python-developers-js": () => import("./../../../src/pages/services/web-development/hire-python-developers.js" /* webpackChunkName: "component---src-pages-services-web-development-hire-python-developers-js" */),
  "component---src-pages-services-web-development-index-js": () => import("./../../../src/pages/services/web-development/index.js" /* webpackChunkName: "component---src-pages-services-web-development-index-js" */),
  "component---src-pages-services-web-development-old-js": () => import("./../../../src/pages/services/web-development/old.js" /* webpackChunkName: "component---src-pages-services-web-development-old-js" */),
  "component---src-pages-showcase-ar-vr-en-js": () => import("./../../../src/pages/showcase/ar-vr/en.js" /* webpackChunkName: "component---src-pages-showcase-ar-vr-en-js" */),
  "component---src-pages-showcase-ar-vr-no-js": () => import("./../../../src/pages/showcase/ar-vr/no.js" /* webpackChunkName: "component---src-pages-showcase-ar-vr-no-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-verticals-ar-vr-js": () => import("./../../../src/pages/verticals/ar-vr.js" /* webpackChunkName: "component---src-pages-verticals-ar-vr-js" */),
  "component---src-pages-verticals-edtech-js": () => import("./../../../src/pages/verticals/edtech.js" /* webpackChunkName: "component---src-pages-verticals-edtech-js" */),
  "component---src-pages-verticals-fintech-js": () => import("./../../../src/pages/verticals/fintech.js" /* webpackChunkName: "component---src-pages-verticals-fintech-js" */),
  "component---src-pages-verticals-games-js": () => import("./../../../src/pages/verticals/games.js" /* webpackChunkName: "component---src-pages-verticals-games-js" */),
  "component---src-pages-verticals-healthtech-js": () => import("./../../../src/pages/verticals/healthtech.js" /* webpackChunkName: "component---src-pages-verticals-healthtech-js" */),
  "component---src-pages-verticals-iot-js": () => import("./../../../src/pages/verticals/iot.js" /* webpackChunkName: "component---src-pages-verticals-iot-js" */),
  "component---src-pages-verticals-js": () => import("./../../../src/pages/verticals.js" /* webpackChunkName: "component---src-pages-verticals-js" */)
}

